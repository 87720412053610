.title-text {
  color: var(--p-lighter-grey, #9a9fab);
  text-transform: none !important;
}

.home-banner {
  margin-top: -5rem;
  padding: 0 0;
  height: 900px;
  min-height: 500px;

  .side-container {
    align-content: flex-start;
  }

  .text-container {
    padding: 30px;
    justify-content: center;
    align-items: start;
    flex-shrink: 0;

    border-radius: 10px;
    background: rgba(4, 14, 11, 0.35);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    margin-bottom: 30px;

    .title {
      text-shadow: 0 0 5px #FFF;
    }
  }

  .h3 {
    text-transform: none !important;
  }

  .home-banner-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .app-image {
      width: 25%;
    }
  }

  .logo {
    max-width: 75%;
  }

  .hero-background-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    padding: 0;
  }

  .unlock-header {
    padding-bottom: 12px;
  }

  .home-content {
    width: 100%;

    small {
      max-width: 30rem;
    }
  }
}



@media (max-width: 1700px) {
  .home-banner {

    height: 1100px;
    min-height: 500px;

    .home-banner-container {
      flex-direction: column-reverse !important;
      justify-content: center;

      .side-container {
        margin-bottom: 25px;

        .title {
          text-align: center;
        }

        .trusted-by {
          align-items: center;
        }
      }

      .app-image {
        width: 300px;
      }
    }
  }
}


@media (max-width: 900px) {}

@media (max-width: 840px) {
  .home-banner {
    height: 1100px;

    .home-banner-container {
      flex-direction: column;

      .logo {
        width: 80%;
      }

    }
  }
}

@media (max-width: 600px) {
  html {
    font-size: 11px;
  }

  .home-banner {
    height: 850px;

    .home-banner-container {
      .app-image {
        width: 200px;
      }
    }
  }
}